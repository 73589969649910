import React from "react";
import DropdownLoginForm from "../Components/dropdownLoginForm";
import EcoprotectorUI from "../Components/ProductOverviews/EcoprotectorOverviewUI/EcoprotectorOverviewUI";

const PlayPage = () => {
    return (
        <div>
            <h1>This is a Play Ground</h1>
            <EcoprotectorUI />
        </div>
    );
};

export default PlayPage;
