import React from "react";
import ProductHomeNavBar from "./productHomeNavBar";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ConstructionIcon from "@mui/icons-material/Construction";
const ProductHomePage = () => {
    return (
        <div>
            <ProductHomeNavBar />
            <div className="container">
                <img
                    src="/imgs/fakebanner.png"
                    alt=""
                    style={{ width: "100%", height: "auto", objectFit: "fill" }}
                />
                <div className="section1" style={{ backgroundColor: "white", paddingTop: "80px" }}>
                    <div className="article_wrapper">
                        <h2 className="title">SPECIFYING PROTECTOR®</h2>
                        <p className="paragraph-blue" style={{ color: "grey" }}>
                            The Specifying Protector Portal is an online repository for Fielders®
                            product literature, making our product literature accessible whenever
                            and wherever you need.
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{ color: "black", fontWeight: "400", lineHeight: "1.3em" }}
                        >
                            Our products are renowned for their quality and are engineered to
                            Australian standards BS4994-1987 and ASME RPT1. Protector has improved
                            the ease of device installation by eliminating the need for confined
                            space entry and the requirement to access the base of site excavations.
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{ color: "black", fontWeight: "400", lineHeight: "1.3em" }}
                        >
                            Protector products are constructed using the advanced chop hoop filament
                            winding process which ensures circumferential as well as longitudinal
                            strength. Every Protector product has a smooth molded resin rich inner
                            corrosion barrier layer and an external resin barrier. Being
                            manufactured in FRP (Fibre Reinforced Plastic) Protector products are
                            light, easy to handle and easy to instal
                        </p>
                    </div>
                    <div className="video_wrapper">
                        <iframe
                            className="elementor-video"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            title="Video Placeholder"
                            width="640"
                            height="360"
                            src="https://www.youtube.com/embed/XHOmBV4js_E?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fspecifying.protector.com.au&amp;widgetid=1"
                            id="widget2"
                        ></iframe>
                    </div>
                </div>

                <div className="section2" style={{ paddingBottom: "20px" }}>
                    <h2 style={{ fontSize: "30px" }}>SPECIFYING Protector ® LATEST RELEASES</h2>
                    <p className="article_white" style={{ fontWeight: "400", lineHeight: "1.3em" }}>
                        Specifying Protector ® is being constantly updated with new profiles,
                        technical literature and exclusive tools and resources to help you specify ®
                        range with accuracy and ease. If you have not been granted access to our
                        exclusive content yet, you can request access here.
                    </p>
                    <div className="wrapper-row">
                        <div className="tornato_gpt">
                            <img
                                src="/imgs/home_tornado_gpt-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Tornado-GPT</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Generate specification documentation with SpeCreator. Ensuring your
                                specifications capture important design, fixing and installation
                                details to protect you and your client’s project needs.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontWeight: "600",
                                    fontSize: "16px", // Optional: Adjust font size
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                }}
                            >
                                <ConstructionIcon fontSize="large" sx={{ paddingRight: "8px" }} />
                                CREATE
                            </button>
                        </div>
                        <div className="hydroprotector">
                            <img
                                src="/imgs/home.hydroprotector-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Hydroprotector</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Protector® is proud to announce the new KingFlor Design Suite is now
                                available for download. Once logged in you can access the software
                                along with other Fielders® exclusive profiles and their design
                                files.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontSize: "16px", // Optional: Adjust font size
                                    fontWeight: "600",
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                }}
                            >
                                <ConstructionIcon fontSize="large" sx={{ paddingRight: "8px" }} />
                                CREATE
                            </button>
                        </div>
                        <div className="trapIt">
                            <img
                                src="/imgs/home_trap_it-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Trap IT</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Fielders® is pleased to provide access to CAD and Revit files for
                                BIM. Our exclusive profiles and their design files are available for
                                all logged in users and can be accessed from profile pages and the
                                downloads page.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontSize: "16px", // Optional: Adjust font size
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                    fontWeight: "600",
                                }}
                            >
                                <ConstructionIcon fontSize="large" sx={{ paddingRight: "8px" }} />
                                CREATE
                            </button>
                        </div>
                    </div>
                </div>

                <div className="section1" style={{ backgroundColor: "white" }}>
                    <div className="article_wrapper">
                        <h2 className="title">
                            INNOVATING SUSTAINABLE SOLUTIONS TO PROTECT OUR ENVIRONMENT AND FUTURE
                        </h2>
                        <p className="paragraph-blue" style={{ color: "grey" }}>
                            ONE STORMWATER SOLUTION AT A TIME
                        </p>
                        <p className="paragraph-gray" style={{ color: "black", fontWeight: "400" }}>
                            Protector is a family-run Australian company that specializes in
                            treating stormwater and making fiberglass products. With 18 years of
                            experience, they focus on improving their designs and manufacturing
                            processes. Their stormwater treatment products meet high Australian
                            standards and are known for their quality. Protector has simplified
                            installation by removing the need for confined space entry and deep
                            digging.
                        </p>
                        <p className="paragraph-gray" style={{ color: "black", fontWeight: "400" }}>
                            Our products are made using a special process that makes them strong in
                            all directions. Each product has a smooth inner layer that resists
                            corrosion and an outer protective layer. Made from Fiber Reinforced
                            Plastic (FRP), they are lightweight, easy to handle, and durable. FRP is
                            a sustainable material that lasts longer than traditional materials,
                            doesn’t rust, and withstands tough conditions, which means lower
                            maintenance costs and less environmental impact. Protector’s products
                            are strong, long-lasting, and easy to maintain, making them an excellent
                            choice for stormwater treatment.
                        </p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: "#005f7f", // Button background color
                                    color: "white", // Text color
                                    border: "none", // Remove border
                                    borderRadius: "20px", // Rounded corners
                                    padding: "8px 16px", // Spacing inside the button
                                    fontSize: "16px", // Font size
                                    cursor: "pointer", // Pointer cursor on hover
                                    outline: "none", // Removes focus outline
                                    marginTop: "20px", // Spacing from the top
                                }}
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div className="video_wrapper">
                        <img src="/imgs/section3_pic.jpg" alt="" />
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#32698f", // Background color of the container
                        display: "flex", // Use flexbox for alignment
                        flexDirection: "column", // Stack items vertically
                        justifyContent: "center", // Center items vertically
                        alignItems: "center", // Center items horizontally
                        textAlign: "center", // Optional: Center-align text
                        paddingBottom: "30px", // Optional: Add padding to the bottom
                        marginBottom: "35px", // Optional: Add margin to the bottom
                    }}
                >
                    <h2 style={{ color: "white", marginBottom: "16px", fontSize: "30px" }}>
                        DOWNLOAD OUR CAPABILITY STATEMENT
                    </h2>
                    <button
                        style={{
                            backgroundColor: "#32698f", // Button background color
                            color: "white", // Text color
                            border: "2px solid white", // Correctly sets border with width, style, and color

                            borderRadius: "20px", // Rounded corners
                            padding: "8px 16px", // Spacing inside the button
                            fontSize: "16px", // Font size
                            cursor: "pointer", // Pointer cursor on hover
                            fontWeight: "700",
                        }}
                    >
                        DOWNLOAD
                    </button>
                </div>

                <div
                    className="section4"
                    style={{
                        marginBottom: "35px",
                        paddingLeft: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    <div className="wrapper0">
                        <img
                            src="/imgs/computer.png"
                            alt=""
                            style={{
                                width: "100%", // Makes the image responsive and fit its container width
                                height: "auto", // Maintains the aspect ratio
                                objectFit: "contain", // Ensures the image scales within its container
                            }}
                        />
                    </div>

                    <div
                        className="wrapper1"
                        style={{
                            paddingLeft: "50px",
                            paddingTop: "60px",
                        }}
                    >
                        <h2
                            style={{
                                color: "#32698f",
                                fontFamily: "Roboto, Sans-serif",
                                fontWeight: 600,
                                fontSize: "30px",
                                lineHeight: "1.3em",
                            }}
                        >
                            SPECIFYING ® PREMIUM LOGIN ACCESS NOW AVAILABLE
                        </h2>

                        <p
                            style={{
                                color: "black",
                                marginTop: "0px",
                                marginBottom: "70px",
                                fontWeight: "400",
                            }}
                        >
                            Login access to exclusive Specifying Protector® product literature is
                            available now. Once registered you’ll get full access to literature for
                            our premium products as well as full access to all product design files
                            and details. Not a registered user yet? Register
                        </p>
                        <div
                            className="wrapper2"
                            style={{
                                display: "flex",
                                flexDirection: "row", // horizontal alignment
                                gap: "20px", // adds space between buttons
                                alignItems: "center", // vertically centers the buttons
                            }}
                        >
                            <button
                                onClick={() => (window.location.href = "/signup")}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000000",
                                    padding: "10px 20px",
                                    paddingLeft: "0px",
                                    marginLeft: "0px",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                }}
                            >
                                <PersonAddAlt1Icon fontSize="large" sx={{ marginRight: "15px" }} />
                                REGISTER TODAY
                            </button>
                            <button
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000000",
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                }}
                                onClick={() => (window.location.href = "/login")}
                            >
                                <LoginIcon fontSize="large" sx={{ marginRight: "15px" }} />
                                LOG IN
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#CBCCD5",
                        background: "linear-gradient(to right, #CBCCD5, white)", // Replace with your desired colors

                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        paddingBottom: "30px",
                        marginBottom: "35px",
                        paddingRight: "150px",
                        paddingLeft: "150px",
                        paddingTop: "30px",
                    }}
                >
                    <h2 style={{ fontSize: "30px" }}>EXPLORE OUR RECENT PROJECTS</h2>
                    <button
                        style={{
                            backgroundColor: "#005f7f", // Button background color
                            color: "white", // Text color
                            border: "none", // Remove border
                            borderRadius: "20px", // Rounded corners
                            padding: "12px 16px", // Spacing inside the button
                            fontSize: "16px", // Font size
                            cursor: "pointer", // Pointer cursor on hover
                            outline: "none", // Removes focus outline
                            marginTop: "20px", // Spacing from the top
                            fontWeight: "700",
                        }}
                    >
                        VIEW PROJECTS
                    </button>
                </div>

                <footer
                    style={{
                        justifyContent: "center",
                        backgroundColor: "#424242",
                        height: "70vh",
                    }}
                >
                    {/* <div className="wrapper1">
                        <img
                            className="img1"
                            src="/imgs/Protector-Text-Black-1-300x25.png"
                            alt=""
                        />
                        <img className="img2" src="/imgs/Saving-Nature-e1725214906424.png" alt="" />
                    </div>

                    <div className="links">
                        <span className="span1">Aout</span>
                        <span className="span2">Guarantee And Warranties</span>
                        <span className="span3">Legals</span>
                        <span className="span4">Contact</span>
                    </div> */}
                    <h2 style={{ color: "white" }}>STANDARD WEBSITE FOOTER</h2>
                </footer>
            </div>
        </div>
    );
};

export default ProductHomePage;
