import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Paper } from "@mui/material";
import {
    Home as HomeIcon,
    Dashboard as DashboardIcon,
    Person as PersonIcon,
    Settings as SettingsIcon,
    Mail as MailIcon,
} from "@mui/icons-material";

const SIDEBAR_WIDTH = 240;

const dashboardSideBar = () => {
    const menuItems = [
        { text: "Home", icon: <HomeIcon />, path: "/" },
        { text: "My Projects", icon: <DashboardIcon />, path: "/dashboard" },
        { text: "Personal Info", icon: <PersonIcon />, path: "/profile" },
        { text: "Messages", icon: <MailIcon />, path: "/messages" },
        { text: "Settings", icon: <SettingsIcon />, path: "/settings" },
    ];

    return (
        <Paper
            elevation={3}
            sx={{
                width: SIDEBAR_WIDTH,
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                borderRadius: 0,
                backgroundColor: "background.paper",
            }}
        >
            <Box
                sx={{
                    padding: 2,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                }}
            >
                <Typography variant="h6" component="h1">
                    Protector
                </Typography>
            </Box>

            <List sx={{ padding: 2 }}>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        onClick={() => (window.location.href = item.path)}
                        sx={{
                            borderRadius: 1,
                            mb: 1,
                            "&:hover": {
                                backgroundColor: "action.hover",
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default dashboardSideBar;
