import { useContext, useState, useRef } from "react";
import { Box, TextField, Button, Typography, Snackbar, Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "./Helpers/apiClient";
import AuthContext from "./Helpers/auth-context";

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [notification, setNotification] = useState({ message: "", severity: "error" });
    const firstInputRef = useRef(null);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post("/api/Account/login", formData);

            if (response.status === 200) {
                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: {
                        user: response.data.user,
                        emailVerified: response.data.isEmailVerified,
                    },
                });
                setNotification({
                    message: "Login successful! Redirecting...",
                    severity: "success",
                });
                setTimeout(() => {
                    navigate("/dashboard");
                }, 2000);
            }
        } catch (error) {
            console.error("Login failed", error);
            if (error.response && error.response.data && typeof error.response.data === "object") {
                setNotification({
                    message: error.response?.data?.message || "Login failed. Please try again.",
                    severity: "error",
                });
            } else {
                setNotification({
                    message: error.response?.data,
                    severity: "error",
                });
            }
        }
    };

    const handleSnackbarClose = () => {
        setNotification({ message: "", severity: "error" });
    };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#1a2238",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "450px",
                    backgroundColor: "#2d3b56",
                    borderRadius: "10px",
                    padding: "40px",
                    boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                }}
            >
                <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
                    <AccountCircleIcon sx={{ fontSize: 60, color: "#fff" }} />
                    <Typography variant="h4" sx={{ color: "#fff" }}>
                        Login
                    </Typography>
                </Box>

                <Box component="form" onSubmit={handleSubmit}>
                    <Typography variant="body2" sx={{ color: "#fff", marginBottom: "8px" }}>
                        Email
                    </Typography>
                    <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange}
                        inputRef={firstInputRef}
                        InputProps={{
                            sx: {
                                backgroundColor: "#fff",
                                color: "#000",
                                height: "40px",
                                padding: "0",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#58627b",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8da0b3",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#fff",
                                },
                            },
                        }}
                        sx={{ marginBottom: "20px" }}
                    />

                    <Typography variant="body2" sx={{ color: "#fff", marginBottom: "8px" }}>
                        Password
                    </Typography>
                    <TextField
                        fullWidth
                        name="password"
                        type="password"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleChange}
                        InputProps={{
                            sx: {
                                backgroundColor: "#fff",
                                color: "#000",
                                height: "40px",
                                padding: "0",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#58627b",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8da0b3",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#fff",
                                },
                            },
                        }}
                        sx={{ marginBottom: "25px" }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: "#58627b",
                            color: "#fff",
                            marginBottom: "20px",
                            "&:hover": {
                                backgroundColor: "#6b7693",
                            },
                        }}
                    >
                        Log In
                    </Button>

                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: "#58627b",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#6b7693",
                                },
                            }}
                        >
                            <Link
                                to="/signup"
                                style={{ textDecoration: "none", color: "inherit", width: "100%" }}
                            >
                                Register
                            </Link>
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: "#58627b",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#6b7693",
                                },
                            }}
                            onClick={() => navigate("/forgot-password")}
                        >
                            Lost Password
                        </Button>
                    </Box>
                </Box>

                <Snackbar
                    open={!!notification.message}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={notification.severity}
                        sx={{ width: "100%" }}
                    >
                        {notification.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default LoginPage;
