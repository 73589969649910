import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Alert, CircularProgress } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import apiClient from "../Helpers/apiClient";

const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: email || "",
        token: token || "",
        newPassword: "",
        confirmPassword: "",
    });

    const [isValidToken, setIsValidToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [countdown, setCountdown] = useState(3); // Add countdown state

    // Add countdown effect
    useEffect(() => {
        let timer;
        if (isCompleted && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else if (countdown === 0) {
            navigate("/");
        }
        return () => clearInterval(timer);
    }, [isCompleted, countdown, navigate]);

    useEffect(() => {
        const verifyToken = async () => {
            if (!token || !email) {
                setIsValidToken(false);
                setIsLoading(false);
                return;
            }

            try {
                const response = await apiClient.get(
                    `/api/account/verify-reset-token?token=${token}&email=${email}`
                );
                setIsValidToken(response.data.isValid);
            } catch (error) {
                setIsValidToken(false);
                setError("Invalid or expired reset link");
            } finally {
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [token, email]);

    const handleChange = (e) => {
        if (!isCompleted) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isCompleted) return;

        if (formData.newPassword !== formData.confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        setIsSubmitting(true);
        setError("");
        setMessage("");

        try {
            const response = await apiClient.post("/api/account/reset-password", formData);
            setMessage(response.data.message);
            setIsCompleted(true);
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred");
            setIsCompleted(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!isValidToken) {
        return (
            <Box sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 2 }}>
                <Alert severity="error">
                    Invalid or expired reset link. Please request a new password reset.
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => navigate("/forgot-password")}
                >
                    Back to Reset Password
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Reset Password
            </Typography>

            {isCompleted ? (
                <Box>
                    {message && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {message}
                            <Typography sx={{ mt: 1 }}>
                                Redirecting to login in {countdown} seconds...
                            </Typography>
                        </Alert>
                    )}
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                            <Typography sx={{ mt: 1 }}>
                                Redirecting to login in {countdown} seconds...
                            </Typography>
                        </Alert>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={() => navigate("/")}
                    >
                        Back to Login
                    </Button>
                </Box>
            ) : (
                <>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            required
                            margin="normal"
                            disabled={isSubmitting}
                        />
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                            margin="normal"
                            disabled={isSubmitting}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : "Reset Password"}
                        </Button>
                    </form>
                </>
            )}
        </Box>
    );
};

export default ResetPasswordPage;