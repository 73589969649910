import React from "react";
import SignUpForm from "../Components/signupForm";
import { Box } from "@mui/material";

const SignupPage = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#1a2238",
            }}
        >
            <SignUpForm />
        </Box>
    );
};

export default SignupPage;
