import React, { useState } from "react";
import { TextField, Button, Box, Typography, Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "../Helpers/apiClient";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError("");
        setMessage("");

        try {
            const response = await apiClient.post("/api/account/forgot-password", { email });
            setMessage(response.data.message);
            setIsCompleted(true); // Set completed on success
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Reset Password
            </Typography>

            {message && (
                <Alert
                    severity="success"
                    sx={{
                        mb: 2,
                        "& .MuiAlert-message": {
                            fontSize: "1.1rem", // or you can use larger sizes like '1.2rem'
                        },
                    }}
                >
                    {message}
                </Alert>
            )}
            {error && (
                <Alert
                    severity="error"
                    sx={{
                        mb: 2,
                        "& .MuiAlert-message": {
                            fontSize: "1.1rem",
                        },
                    }}
                >
                    {error}
                </Alert>
            )}

            {isCompleted ? (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate("/")}
                    sx={{ mt: 2 }}
                >
                    Back to Home
                </Button>
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        margin="normal"
                        disabled={isLoading}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Send Reset Link"}
                    </Button>
                </form>
            )}
        </Box>
    );
};

export default ForgotPassword;