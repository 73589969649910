import { useContext, useState, useRef, useEffect } from "react";
import { IconButton, Box, TextField, Button, Typography, Snackbar, ClickAwayListener, Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../Helpers/apiClient"; // Adjust the import path as needed
import AuthContext from "../Helpers/auth-context"; // Adjust the import path as needed

const DropdownLoginForm = () => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [notification, setNotification] = useState({ message: "", severity: "error" }); // Manage both message and severity
    const dropdownRef = useRef(null);
    const firstInputRef = useRef(null);
    const { dispatch, state } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post("/api/Account/login", formData);

            if (response.status === 200) {
                // Assuming the login is successful and backend sets JWT in HttpOnly cookie
                dispatch({
                    type: "LOGIN_SUCCESS",
                    payload: {
                        user: response.data.user,
                        emailVerified: response.data.isEmailVerified,
                    },
                });
                setNotification({ message: "Login successful! Redirecting...", severity: "success" });
                handleClose();
                setTimeout(() => {
                    navigate("/dashboard"); // Redirect to dashboard after 2 seconds
                }, 2000);
            }
        } catch (error) {
            console.error("Login failed", error);
            // if (error.response && error.response.data && typeof error.response.data === 'object') {
            if (error.response && error.response.data && typeof error.response.data === "object") {
                setNotification({
                    message: error.response?.data?.message || "Login failed. Please try again.",
                    severity: "error",
                });
            } else {
                setNotification({
                    message: error.response?.data,
                    severity: "error",
                });
            }
        }
    };

    useEffect(() => {
        if (open && firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [open]);

    const handleSnackbarClose = () => {
        setNotification({ message: "", severity: "error" }); // Reset notification
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ position: "relative" }}>
                <IconButton color="inherit" onClick={handleToggle}>
                    <AccountCircleIcon sx={{ fontSize: 45, color: "white" }} />
                </IconButton>
                {open && (
                    <Box
                        ref={dropdownRef}
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: "100%",
                            width: "350px",
                            backgroundColor: "#2d3b56",
                            color: "#fff",
                            borderRadius: "10px",
                            padding: "20px",
                            zIndex: 1000,
                            boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                        }}
                    >
                        {state.isAuthenticated && state.user ? (
                            // Render the "Go to Dashboard" button and greet the user
                            <>
                                <Typography sx={{ marginBottom: "10px" }} variant="h4">
                                    Hi {state.user.firstName}, welcome back!
                                </Typography>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#58627b",
                                        color: "#fff",
                                        padding: "5px",
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => navigate("/dashboard")}
                                >
                                    Go to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#58627b",
                                        color: "#fff",
                                        padding: "5px",
                                    }}
                                    onClick={() => {
                                        // Clear localStorage and handle logout logic
                                        localStorage.removeItem("authState");
                                        setNotification({
                                            message: "Log out successfully! Redirecting...",
                                            severity: "success",
                                        });
                                        setTimeout(() => {
                                            dispatch({ type: "LOGOUT" }); // Trigger logout action
                                            navigate("/"); // Redirect to home after 2 seconds
                                        }, 2000);
                                    }}
                                >
                                    Log out
                                </Button>
                            </>
                        ) : (
                            // Render the login form
                            <Box component="form" onSubmit={handleSubmit} sx={{ padding: "10px" }}>
                                <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                                    <AccountCircleIcon sx={{ fontSize: 40, color: "#fff" }} />
                                    <Typography variant="h6" sx={{ color: "#fff" }}>
                                        Login
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="body2"
                                    sx={{ color: "#fff", marginBottom: "8px" }}
                                >
                                    Email
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="email"
                                    variant="outlined"
                                    value={formData.email}
                                    onChange={handleChange}
                                    inputRef={firstInputRef}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            height: "40px",
                                            padding: "0",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#58627b",
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#8da0b3",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#fff",
                                            },
                                        },
                                    }}
                                    sx={{ marginBottom: "15px" }}
                                />

                                <Typography
                                    variant="body2"
                                    sx={{ color: "#fff", marginBottom: "8px" }}
                                >
                                    Password
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    value={formData.password}
                                    onChange={handleChange}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            height: "40px",
                                            padding: "0",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#58627b",
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#8da0b3",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#fff",
                                            },
                                        },
                                    }}
                                    sx={{ marginBottom: "15px" }}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#58627b",
                                        color: "#fff",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Log In
                                </Button>

                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#58627b", color: "#fff" }}
                                    >
                                        <Link
                                            to="./signup"
                                            style={{ textDecoration: "none", color: "inherit" }}
                                        >
                                            Register
                                        </Link>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#58627b", color: "#fff" }}
                                        onClick={() => navigate("/forgot-password")}
                                    >
                                        Lost Password
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}

                {/* Snackbar with Alert component for success/error handling */}
                <Snackbar
                    open={!!notification.message}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={notification.severity}
                        sx={{ width: "100%" }}
                    >
                        {notification.message}
                    </Alert>
                </Snackbar>
            </Box>
        </ClickAwayListener>
    );
};

export default DropdownLoginForm;
