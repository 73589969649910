import React, { useState, useContext } from "react";
import { Box, Typography, Dialog, Grow, IconButton, Snackbar, Alert } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import ProductDialog from "./productDialog";
import DropdownLoginForm from "../../Components/dropdownLoginForm";
import AuthContext from "../../Helpers/auth-context";

const ProductHomeNavBar = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isProductSnackbarOpend, SetisProductSnackbarOpend] = useState(false);
    const { dispatch, state } = useContext(AuthContext);

    const products = [
        { name: "TRAP-IT", image: "/imgs/protector_image/TRAP-IT.png" },
        {
            name: "ECOPROTECTOR",
            image: "/imgs/protector_image/ECOPROTECTOR.png",
            bigImage: "/imgs/protector_image/big_image/TRAP-IT.png",
        },
        {
            name: "Tornado - GPT",
            image: "/imgs/protector_image/Tornado-GPT.png",
            bigImage: "/imgs/protector_image/Tornado-GPT.png",
        },
        {
            name: "HYDROPROTECTOR",
            image: "/imgs/protector_image/HYDROPROTECTOR.png",
            bigImage: "/imgs/protector_image/HYDROPROTECTOR.png",
        },
        {
            name: "STORMBRAKE",
            image: "/imgs/protector_image/STORMBRAKE2.png",
            bigImage: "/imgs/protector_image/STORMBRAKE.png",
        },
        {
            name: "DRAINSHIELD",
            image: "/imgs/protector_image/DRANSHIELD.png",
            bigImage: "/imgs/protector_image/DRANSHIELD.png",
        },
        {
            name: "XTREAMFILTER",
            image: "/imgs/protector_image/XTREAMFILTER.png",
            bigImage: "/imgs/protector_image/XTREAMFILTER.png",
        },

        // { name: "BIOPROTECTOR", image: "/imgs/protector_image/BIOPROTECTOR.png" },

        {
            name: "ENVIROPROTECTOR",
            image: "/imgs/protector_image/ENVIROPROTECTOR.png",
            bigImage: "/imgs/protector_image/ENVIROPROTECTOR.png",
        },
        {
            name: "ENVIROSAVE",
            image: "/imgs/protector_image/ENVIROSAVE.png",
            bigImage: "/imgs/protector_image/ENVIROSAVE.png",
        },
        {
            name: "STORMPROTECTOR",
            image: "/imgs/protector_image/STORMPROTECTOR.png",
            bigImage: "/imgs/protector_image/STORMPROTECTOR.png",
        },

        // { name: "XTREAMPROTECTOR", image: "/imgs/protector_image/XTREAMPROTECTOR.png" },
        {
            name: "HYDROVAULT",
            image: "/imgs/protector_image/HYDROVAULT2.png",
            bigImage: "/imgs/protector_image/HYDROVAULT.png",
        },
        {
            name: "PACKAGED PUMP STATIONS",
            image: "/imgs/protector_image/Packaged Pump Stations.png",
            bigImage: "/imgs/protector_image/Packaged Pump Stations2.png",
        },
    ];

    const handleClick = (event, product) => {
        console.log(state.isAuthenticated);
        if (!state.isAuthenticated) {
            SetisProductSnackbarOpend(true);
        } else {
            setSelectedProduct(product);
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setSelectedProduct(null);
        setAnchorEl(null);
    };

    return (
        <Box
            component="nav"
            sx={{
                boxShadow: 3,
                backgroundColor: "background.paper",
                position: "relative",
                zIndex: 1,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    component="img"
                    src="/imgs/protector_image/Protector-Text-Black-1.png"
                    alt="Fielders Logo"
                    sx={{
                        width: 120,
                        height: 120,
                        objectFit: "contain",
                        px: 4,
                        flex: "2",
                    }}
                />
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(6, 1fr)",
                        flex: "8",
                    }}
                >
                    {products.map((product, index) => (
                        <Box
                            key={index}
                            onClick={(e) => handleClick(e, product)}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid",
                                borderColor: "divider",
                                p: 2,
                                cursor: "pointer",
                                "&:hover": {
                                    bgcolor: "action.hover",
                                },
                            }}
                        >
                            <Box
                                component="img"
                                src={product.image}
                                alt={product.name}
                                sx={{
                                    width: 90,
                                    height: 90,
                                    objectFit: "contain",
                                    mb: 1,
                                }}
                            />
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {product.name}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        backgroundColor: "#4d5d7c",
                        borderRadius: "5px",
                        height: "300px",
                        justifyContent: "space-between",
                        // marginLeft: "2vw",
                    }}
                >
                    <IconButton color="inherit">
                        <SearchIcon sx={{ fontSize: 45, color: "white" }} />
                    </IconButton>
                    <IconButton color="inherit">
                        <StarIcon sx={{ fontSize: 45, color: "white" }} />
                    </IconButton>
                    <IconButton color="inherit">
                        <ChatBubbleIcon sx={{ fontSize: 45, color: "white" }} />
                    </IconButton>

                    <DropdownLoginForm />
                </Box>
            </Box>

            <ProductDialog
                open={Boolean(selectedProduct)}
                onClose={handleClose}
                product={selectedProduct}
                anchorEl={anchorEl}
            />
            <Snackbar
                open={isProductSnackbarOpend}
                autoHideDuration={6000}
                onClose={() => SetisProductSnackbarOpend(false)}
            >
                <Alert
                    onClose={() => SetisProductSnackbarOpend(false)}
                    severity={"warning"}
                    sx={{ width: "100%" }}
                >
                    You need to login to view the product
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductHomeNavBar;
