import React from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const EcoprotectorOverviewUI = () => {
    return (
        <Box
            sx={{
                display: "flex",
                height: "100%", // For taking full height of the parent container
                minHeight: "100%", // Fixed height even when no content
                overflow: "scroll",
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    height: "100%",
                    minHeight: "100%",
                    padding: "25px",
                    maxWidth: "33.3%",
                }}
            >
                <Box>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                            color: "#1c6790",
                            display: "inline",
                            verticalAlign: "50%",
                        }}
                    >
                        EcoProtector Highlights:
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#1c6790", display: "inline" }}
                    >
                        • Efficient Plastic Removal
                    </Typography>
                    <Typography
                        sx={{
                            color: "#1c6790",
                            display: "inline",
                            marginLeft: 1,
                            verticalAlign: "10%",
                        }}
                    >
                        – Captures hard and soft plastics, keeping waterways clean.
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#1c6790", display: "inline" }}
                    >
                        • Simple Maintenance Access
                    </Typography>
                    <Typography
                        sx={{
                            color: "#1c6790",
                            display: "inline",
                            marginLeft: 1,
                            verticalAlign: "10%",
                        }}
                    >
                        – Designed for quick and hassle-free servicing.
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#1c6790", display: "inline" }}
                    >
                        • Comprehensive Pollutant Filtration
                    </Typography>
                    <Typography
                        sx={{
                            color: "#1c6790",
                            display: "inline",
                            marginLeft: 1,
                            verticalAlign: "10%",
                        }}
                    >
                        – Effectively removes sediment and gross pollutants.
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#1c6790", display: "inline" }}
                    >
                        • Lightweight FRP Durability
                    </Typography>
                    <Typography
                        sx={{
                            color: "#1c6790",
                            display: "inline",
                            marginLeft: 1,
                            verticalAlign: "10%",
                        }}
                    >
                        – Strong, corrosion-resistant, and easy to install.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        my: { sm: "18px" },
                        // Applies 5px margin-top at the "sm" breakpoint
                    }}
                >
                    {" "}
                    <Typography
                        sx={{
                            fontWeight: 100,
                            mb: 0.8,
                            color: "#231f20",
                            lineHeight: "1.2",
                            fontSize: "14px",
                        }}
                    >
                        The Ecoprotector is a high-capacity trash and litter removal GPT (Gross
                        Pollutant Trap) with particulate removal down to 3mm.
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 100,
                            mb: 0.8,
                            color: "#231f20",
                            lineHeight: "1.2",
                            fontSize: "14px",
                        }}
                    >
                        The Ecoprotector has been designed to trap solid debris including sediment
                        and hydrocarbons under low velocity conditions.
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 100,
                            mb: 0.8,
                            color: "#231f20",
                            lineHeight: "1.2",
                            fontSize: "14px",
                        }}
                    >
                        Perfect for residential neighborhoods, roads, parking lots, and industrial
                        sites, the Ecoprotector captures solid waste, sediment, and hydrocarbons,
                        ensuring clean water is returned to the environment. With its durable
                        construction and low maintenance requirements, it’s the top choice for
                        managing stormwater pollution.
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 100,
                            mb: 0.8,
                            color: "#231f20",
                            lineHeight: "1.2",
                            fontSize: "14px",
                        }}
                    >
                        With its robust build and minimal maintenance needs, the Ecoprotector stands
                        out as the ultimate choice for stormwater pollution management. Employing
                        physical screening, rapid sedimentation, and hydrodynamic separation
                        processes, it guarantees clean water returns to the environment.
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 100,
                            mb: 0.8,
                            color: "#231f20",
                            lineHeight: "1.2",
                            fontSize: "14px",
                        }}
                    >
                        Gross pollutant traps are often used as part of a treatment process with
                        other stormwater filtration devices to remove high loads of sediment and
                        larger solids.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 1, color: "black" }}>
                        TREATMENT EFFICIENCIES
                    </Typography>
                    <TableContainer component={Paper} sx={{ maxWidth: 600, margin: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            // backgroundColor: "#f5f5f5",
                                            color: "#333",
                                            padding: "8px", // Smaller padding for header
                                            textAlign: "center", // Horizontal alignment
                                            verticalAlign: "middle", // Vertical alignment
                                        }}
                                    >
                                        POLLUTANT
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            fontWeight: "bold",
                                            // backgroundColor: "#f5f5f5",
                                            color: "#333",
                                            padding: "8px", // Smaller padding for header
                                            textAlign: "center", // Horizontal alignment
                                            verticalAlign: "middle", // Vertical alignment
                                        }}
                                    >
                                        EFFICIENCY
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Gross Pollutants (GP)
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        100%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Total Suspended Solids (TSS)
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center", // Horizontal alignment
                                            verticalAlign: "middle", // Vertical alignment
                                        }}
                                    >
                                        87%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Total Phosphorus (TP)
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        11%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Total Nitrogen (TN)
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        23%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Petroleum Hydrocarbon
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        99.99%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell sx={{ padding: "8px" }}>
                                        Spill capture (Site specific volume)
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: "white",
                                            width: "50%",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        100%
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box
                sx={{
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    fontFamily: "'Roboto', sans-serif",
                    flexGrow: 2,
                    //  minHeight: "100%", // Full height of the container
                    height: "850px",

                    maxWidth: "66.6%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", // Center the content (image + label container)
                    position: "relative", // Relative positioning for label overlay
                }}
            >
                <Box
                    component="img"
                    src="./imgs/ecoprotectornew1.png"
                    alt="Ecoprotector"
                    sx={{
                        pointerEvents: "none", // Disable all pointer events
                        userSelect: "none", // Prevent text/image selection
                        width: "600px", // Set the image dimensions
                        height: "auto",
                        cursor: "default", // Ensure no text cursor appears
                    }}
                    draggable={false} // Prevent the image from being dragged
                />
                <svg
                    viewBox="0 0 512.003 512.003"
                    style={{
                        position: "absolute",
                        top: "37.5%",
                        left: "70%",
                        width: "15px",
                        height: "15px",
                        pointerEvents: "none", // Allows interaction with labels
                    }}
                >
                    <g>
                        <g>
                            <path
                                d="M507.284,248.364L12.35,0.898C8.894-0.826,4.721-0.007,2.153,2.86c-2.56,2.884-2.867,7.125-0.759,10.351l159.07,242.79
				L1.393,498.792c-2.108,3.226-1.801,7.467,0.759,10.351c1.664,1.86,4.002,2.859,6.383,2.859c1.289,0,2.594-0.29,3.814-0.896
				l494.933-247.467c2.893-1.451,4.719-4.403,4.719-7.637S510.176,249.815,507.284,248.364z"
                                fill="#1c6790"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    viewBox="0 0 512.003 512.003"
                    style={{
                        position: "absolute",
                        top: "37.5%",
                        left: "27%",
                        width: "15px",
                        height: "15px",
                        pointerEvents: "none", // Allows interaction with labels
                    }}
                >
                    <g>
                        <g>
                            <path
                                d="M507.284,248.364L12.35,0.898C8.894-0.826,4.721-0.007,2.153,2.86c-2.56,2.884-2.867,7.125-0.759,10.351l159.07,242.79
				L1.393,498.792c-2.108,3.226-1.801,7.467,0.759,10.351c1.664,1.86,4.002,2.859,6.383,2.859c1.289,0,2.594-0.29,3.814-0.896
				l494.933-247.467c2.893-1.451,4.719-4.403,4.719-7.637S510.176,249.815,507.284,248.364z"
                                fill="black"
                                opacity={0.5}
                            />
                        </g>
                    </g>
                </svg>

                <svg
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none", // Allows interaction with labels
                    }}
                >
                    {/* Line 1: INLET DIVERSION WEIR */}
                    <line x1="37%" y1="23%" x2="5%" y2="23%" stroke="#1c6790" strokeWidth="1.5" />
                    <line
                        x1="37%"
                        y1="23%"
                        x2="37%"
                        y2="42.5%"
                        stroke="#1c6790"
                        strokeWidth="1.5"
                    />
                    <rect
                        x="37.20%"
                        y="42.95%" // Match the line's y1 coordinate
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />

                    {/* Line 2: INLET VORTEX TUBE */}
                    <line x1="47%" y1="48%" x2="5%" y2="48%" stroke="#1c6790" strokeWidth="1.5" />
                    <rect
                        x="47.5%"
                        y="48.25%" // Match the line's y1 coordinate
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />

                    {/* Line 3: OUTLET RISER */}
                    <line
                        x1="47%"
                        y1="66.5%"
                        x2="5%"
                        y2="66.5%"
                        stroke="#1c6790"
                        strokeWidth="1.5"
                    />
                    <rect
                        x="47.5%"
                        y="66.75%" // Match the line's y1 coordinate
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />

                    {/* Line 4: TRASH AND SLUDGE PUMPOUT ACCESS */}
                    <line
                        x1="52%"
                        y1="20.5%"
                        x2="95%"
                        y2="20.5%"
                        stroke="#1c6790"
                        strokeWidth="1.5"
                    />
                    <rect
                        x="52.1%"
                        y="20.75%"
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />

                    {/* Line 5: MAX FLOW BYPASS */}
                    <line
                        x1="40%"
                        y1="37.5%"
                        x2="95%"
                        y2="37.5%"
                        stroke="#1c6790"
                        strokeWidth="1.5"
                    />
                    <rect
                        x="40.1%"
                        y="37.75%"
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />

                    {/* Line 6: OIL CAPTURE */}
                    <line x1="61%" y1="54%" x2="95%" y2="54%" stroke="#1c6790" strokeWidth="1.5" />
                    <rect
                        x="61.1%"
                        y="54.25%"
                        width="5" // Width of the square
                        height="5" // Height of the square
                        fill="none" // Transparent fill
                        stroke="#1c6790" // Border color of the square
                        strokeWidth="1.5" // Border thickness
                        transform="translate(-5, -5)" // Center the square on the coordinate
                    />
                </svg>
                {/* Label 1 - INLET DIVERSION WEIR */}
                <Box
                    sx={{
                        position: "absolute",
                        top: "20%",
                        left: "5%",
                        color: "black",

                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        INLET DIVERSION WEIR
                    </Typography>
                    <Typography variant="body2">
                        The inlet diversion weir of the EcoProtector generates a smooth fluid path
                        to a vortex funnel, allowing for superior pollution removal from stormwater
                        inflows.
                    </Typography>
                </Box>

                {/* Label 2 - INLET VORTEX TUBE */}
                <Box
                    sx={{
                        position: "absolute",
                        top: "45%",
                        left: "5%",
                        color: "black",
                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        INLET VORTEX TUBE
                    </Typography>
                    <Typography variant="body2">
                        The EcoProtector's Inlet vortex funnel creates a seamless laminar
                        centrifugal flow path that helps the separation of various pollutants before
                        transferring into the storage chamber.
                    </Typography>
                </Box>

                {/* Label 3 - OUTLET RISER */}
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "21%",
                        left: "5%",
                        color: "black",
                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        OUTLET RISER
                    </Typography>
                    <Typography variant="body2">
                        The EcoProtector features an outlet riser that is integrated into the outlet
                        and bypass duct, providing added strength and durability to the device.
                    </Typography>
                </Box>

                {/* Label 4 - TRASH AND SLUDGE PUMPOUT ACCESS */}
                <Box
                    sx={{
                        position: "absolute",
                        top: "15%",
                        right: "5%",
                        color: "black",
                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        TRASH AND SLUDGE PUMPOUT ACCESS
                    </Typography>
                    <Typography variant="body2">
                        The EcoProtector is easily maintained due to its trash and sludge pumpout
                        access, providing easy and efficient access for regular maintenance and
                        cleaning.
                    </Typography>
                </Box>

                {/* Label 5 - MAX FLOW BYPASS */}
                <Box
                    sx={{
                        position: "absolute",
                        top: "35%",
                        right: "5%",
                        color: "black",

                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        MAX FLOW BYPASS
                    </Typography>
                    <Typography variant="body2">
                        The EcoProtector has a max flow bypass that allows water to discharge freely
                        over the weir during large stormwater inflows to prevent flow restriction.
                    </Typography>
                </Box>

                {/* Label 6 - OIL CAPTURE */}
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "33%",
                        right: "5%",
                        color: "black",

                        width: "250px",
                        height: "140px",
                    }}
                >
                    <Typography variant="h6" sx={{ lineHeight: 1.2, mb: 2 }}>
                        OIL CAPTURE
                    </Typography>
                    <Typography variant="body2">
                        Captured hydrocarbons rise to the surface of the storage chamber and are
                        retained without resuspension during high flow events.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default EcoprotectorOverviewUI;
